body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 1px;
}

.container {
    /* display: grid;
    flex-direction: column; */
    /* background-color: rgb(0, 255, 21); */
    /* max-height: 1200px; */
}

.header {
 /* border: black 1px solid;  */
    width: 730px;
    height: 1.53in;
    /* background-color: rgb(224, 24, 24); */
    display: grid;
    grid-template-columns: 18% 82%;
    align-items: center; /* Center contents vertically */
    padding: 0;
    /* background-color: green; */
}

.personal-image-parent {
    min-width: 1.45in; /* Responsive */
    max-width: 1.45in;
    min-height: 1.59in; /* Maintain aspect ratio */
    max-height: 1.59in; /* Limit height to fit header */
    /* background-color: green; */
    padding: 0
}

.personal-image {
    border: black 1px solid;
    min-width: 100%; /* Responsive */
    max-width: 100%;
    min-height: 1.53in; /* Maintain aspect ratio */
    max-height: 1.53in; /* Limit height to fit header */
    /* background-color: blue; */
}

.wider-image-parent {
    /* border: black 1px solid; */
    /* height: 1.53in; */
    max-width: 100%;
    max-width: 100%;
    min-height: 1.59in; /* Maintain aspect ratio */
    max-height: 1.59in; /* Limit height to fit header */
    /* background-color: red; */
}


.wider-image {
    /* border: black 1px solid; */
    border: black 1px solid;
    max-width: 100%; /* Take full width of the column */
    min-width: 100%;
    min-height: 1.53in; 
    max-height: 1.53in; 
    /* background-color: black; */
}

.table-main-parent {
    /* background-color: rgb(108, 255, 228); */
    display: grid;
    grid-template-columns: 60% 40%;
    height:523px;
    /* background-color: red; */
}

.title-parent {
    border: black 1px solid;
    background-color: white;
    display: grid;
    grid-template-columns: 60% 20% 20%;
}

.title-parent div {
    border: black 1px solid;
    background-color: #C5D9F1;
    align-items: center; /* Center contents vertically */
}

.table-parent {
    /* background-color: rgb(82, 92, 53); */
    display: grid;
    grid-template-columns: 30% 40% 30%;
    height: 200px;
    
    /* overflow: auto; */
}

.table-parent div {
    border: black 1px solid;
    font-size: small;
    font-weight: bold;
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 20px; /* Fixed height */
    min-height: 20px; /* Allows for expansion */
    /* padding: 0 5px; Optional: Add some padding */
    box-sizing: border-box; /* Ensures padding doesn't affect total height */
    /* overflow: auto; */
    /* background-color: red; */
}

.second-side {
    /* background-color: yellow; */
    display: grid;
    grid-template-rows: 4.32in 1.14in;
    height: 620px;
}

.second-side div {
    background-color: white;
    border: black 1px solid;
}

.full-body-image {
    /* min-height: 4.32in;
    min-width: 2.88in;
    max-height: 4.32in;
    max-width: 2.88in; */
    min-height: 100%;
    min-width: 100%;
    max-height: 100%;
    max-width: 100%;
}

.agent-logo {
    max-height: 100%;
    max-width: 100%;
    min-height: 100%;
    min-width: 100%;
}

.second-section-parent {
    height:10px;
    background-color: blue;
}

.second-section-parent div {
    height: 20px;
}


.specific-exp-atitle-sec,
.specific-exp-etitle-sec,
.exp-trueorfalse-sec {
    border: black 1px solid;
    height: 30px;
    background-color: white;
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
}

.phone-number-sec,
.cphone-number-sec,
.experience-country-sec {
    /* border: black 1px solid; */
    height: 30px;
    background-color: white;
    display: grid;
    grid-template-columns: 40% 30% 30%;
}

.cphone-number-sec {
    border-bottom: #C5D9F1 30px solid;
}

.phone-number-sec div {
    background-color: #90D14D;
    font-size:medium;
    font-weight: bold;
    display: flex;
    justify-content: center;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
}

.cphone-number-sec div {
    background-color: #FBBE8E;
    font-size: medium;
    font-weight: bold;
    display: flex;
    justify-content: center;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
}

.experience-country-sec div {
    font-size: medium;
    font-weight: bold;
    display: flex;
    justify-content: center;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
}


.specific-exp-atitle-sec div,
.specific-exp-etitle-sec div,
.exp-trueorfalse-sec div {
    border: black 1px solid;
    background-color: #C5D9F1;
    font-size: medium;
    font-weight: bold;
    display: flex;
    justify-content: center;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
}

.phone-number-sec div,
.cphone-number-sec div,
.experience-country-sec div {
    border: black 1px solid;
    font-size:small;
}



.passport-image-parent {
    /* background-color: red; */
    display: flex;
    justify-content: center;
    overflow: auto;
    height: 900px;
    /* background-color: red; */
    /* background-color: red; */
    
}

.passport-image {
    border: black 1px solid;
    /* margin-top: 70px; */
    /* margin-left: 30px;
    margin-right: 30px; */
    max-height: 600px;
    max-width: 100%;

    /* overflow: auto; */
    background-color: blue;
}


/*   Inputs Design   */

.cv-builder-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.title {
    text-decoration: underline;
}

.image-upload-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.image-upload {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    margin-top: 20px;
}

.image-preview {
    cursor: pointer;
}

.input-personal-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
}

.no-image {
    font-style: italic;
    color: gray;
}

.file-upload-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.file-upload-form label {
    display: flex;
    justify-content: space-between;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}

.social-share {
    margin-top: 20px;
    text-align: center;
}




/*                    EMBASSY CV DESIGN                      */


.embassy-cv-main-parent{
    margin-left: 15px;
    margin-right: 15px;
}

.embassy-header {
    display: flex;
    justify-content: space-between;
}

/* styles.css */
.react-barcode text {
    display: none;  /* Hides the default text below the barcode */
}

