* {
    margin: 0;
    padding: 0; 
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;


    /* outline: 1px solid #f00 !important; */
  }

  
  
/*** helper classes ***/
.text-center {text-align: center;}
.text-underline{text-decoration: underline;}
.text-italic{font-style: italic;}

.m-0 {margin: 0}
.m-1 {margin: 0.25rem}
.m-2 {margin: 0.5rem}
.m-3 {margin: 0.75rem}
.m-4 {margin: 1rem}
.m-5 {margin: 1.5rem}

.ml-0 {margin-left: 0}
.ml-1 {margin-left: 0.25rem}
.ml-2 {margin-left: 0.5rem}
.ml-3 {margin-left: 0.75rem}
.ml-4 {margin-left: 1rem}
.ml-5 {margin-left: 1.5rem}

.mr-0 {margin-right: 0}
.mr-1 {margin-right: 0.25rem}
.mr-2 {margin-right: 0.5rem}
.mr-3 {margin-right: 0.75rem}
.mr-4 {margin-right: 1rem}
.mr-5 {margin-right: 1.5rem}

.mt-0 {margin-top: 0}
.mt-1 {margin-top: 0.25rem}
.mt-2 {margin-top: 0.5rem}
.mt-3 {margin-top: 0.75rem}
.mt-4 {margin-top: 1rem}
.mt-5 {margin-top: 1.5rem}

.mb-0 {margin-bottom: 0}
.mb-1 {margin-bottom: 0.25rem}
.mb-2 {margin-bottom: 0.5rem}
.mb-3 {margin-bottom: 0.75rem}
.mb-4 {margin-bottom: 1rem}
.mb-5 {margin-bottom: 1.5rem}


.p-0 {padding: 0}
.p-1 {padding: 0.25rem}
.p-2 {padding: 0.5rem}
.p-3 {padding: 0.75rem}
.p-4 {padding: 1rem}
.p-5 {padding: 1.5rem}

.pl-0 {padding-left: 0}
.pl-1 {padding-left: 0.25rem}
.pl-2 {padding-left: 0.5rem}
.pl-3 {padding-left: 0.75rem}
.pl-4 {padding-left: 1rem}
.pl-5 {padding-left: 1.5rem}

.pr-0 {padding-right: 0}
.pr-1 {padding-right: 0.25rem}
.pr-2 {padding-right: 0.5rem}
.pr-3 {padding-right: 0.75rem}
.pr-4 {padding-right: 1rem}
.pr-5 {padding-right: 1.5rem}

.pt-0 {padding-top: 0}
.pt-1 {padding-top: 0.25rem}
.pt-2 {padding-top: 0.5rem}
.pt-3 {padding-top: 0.75rem}
.pt-4 {padding-top: 1rem}
.pt-5 {padding-top: 1.5rem}

.pb-0 {padding-bottom: 0}
.pb-1 {padding-bottom: 0.25rem}
.pb-2 {padding-bottom: 0.5rem}
.pb-3 {padding-bottom: 0.75rem}
.pb-4 {padding-bottom: 1rem}
.pb-5 {padding-bottom: 1.5rem}

.align-right {text-align: right;}
.d-grid { display: grid;}
.grid-container {display: grid;gap:0.25rem;}
.grid-3-col {display:grid;grid-template-columns: repeat(3,1fr);gap:0 1rem;}
.grid-2-col {display:grid;grid-template-columns: repeat(2,1fr);gap:0 1rem;}
.grid-1-col {display:grid;grid-template-columns: repeat(1,1fr);gap:0 1rem;}
/* .grid-sm {display:grid;grid-template-columns: repeat(1,1fr);gap:0 1rem;} */

.grid-gap-r1{row-gap: 1rem;}
.grid-gap-0{gap:0}

.grid-main-row-1fr-100 {grid-template-rows: 1fr 100px;}

.d-flex{display:flex}


.w-auto{width:auto!important}
.border { border: 1px solid black;}
.border-bottom-0 {border-bottom: none;}
/**********************/