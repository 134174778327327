.header {
    
    padding: 1rem;
    min-width: 100%;
}

header.header {
    background-color: #CED0D2; /* Your desired color */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo-container {
    /* display: flex;
    align-items: center; */
    margin-left: 30px;
}

.logo {
    height: 50px; /* Adjust as needed */
    width: 50px;
    margin-right: 10px; /* Space between logos */
    border-radius: 50%; /* Makes the logo circular */
    object-fit: cover; /* Ensures the image covers the area */
}

.client-logo {
    height: 50px; /* Adjust as needed */
    width: 50px;
    border-radius: 50%; /* Makes the logo circular */
    object-fit: cover; /* Ensures the image covers the area */
}

.nav ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
}

.nav li {
    margin-left: 20px; /* Space between menu items */
}

.nav a {
    font-family:' sans-serif';
    text-decoration: none;
    color: black; /* Bootstrap primary color */
    font-weight: bold;
}

.nav a:hover {
    text-decoration: underline;
}

/* Responsive styles */
@media (max-width: 700px) {
    .header {
        flex-direction: column;
        align-items: flex-start;
       
    }

    .client-logo {
        display: none;
    }

    header.header {
       
        min-height: 200px;
        
    }

    .nav ul {
        flex-direction: column;
        align-items: flex-start;
    }

    .nav li {
        margin-left: 0;
        margin-top: 10px;
    }
}